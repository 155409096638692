.create-card {
  @media (min-width: 1500px) {
    width: 680px;
    min-width: unset;
  }
  min-width: 44vw;
}

.atlaskit-portal {
  z-index: 1000000000 !important;
}

.parent-page-tooltip {
  cursor: pointer;
  transform: translate(1px, 2px);
  max-height: 1rem;
  padding: 0 !important;
}

.input-or-select-container {
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  @media screen and (max-width: 1140px) {
    flex-direction: column;
  }
}

.input-or-select-container-names {
  flex-shrink: 1;
  flex-basis: 250px;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3333;
  font-weight: 600;
  margin-top: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.create-card-input {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: 2px solid #dee1e5 !important;
  border-radius: 3px;
  &:focus {
    outline: none;
    border: 2px solid #4c9aff !important;
  }
  box-sizing: border-box;
  color: #091e42;
  font-size: 14px;
  line-height: 1.4285714285714286;
  overflow: hidden;
  word-wrap: break-word;
  padding: 6px 6px;
  height: 40px;
}

.create-card-select {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4285714285714286;
  word-wrap: break-word;
  padding: 6px 6px;
  height: 40px;

  padding: 0 !important;

  & > :nth-child(2) {
    color: #8993a3;
    outline: none;
    background-color: transparent;
    padding: 0 !important;
  }
  div {
    font-size: 14px;
    line-height: 1.4285714285714286;
  }
}
