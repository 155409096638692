.colorFields {
  display: contents;
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
}

.colorPaletteContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
}

.colorPaletteContainer-colorField {
  cursor: pointer;
  top: 1px;
  left: 1px;
  margin-right: 2px;
  margin-top: 1px;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  
}
