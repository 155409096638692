.Variables-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 320px;
  &--subcontainer {
    padding: 12px 16px;
  }
  &--title {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 16px;
    &-two {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  &--subtitle {
    font-size: 12px;
    padding: 0;
    margin: 0;
    margin-top: 8px;
  }
}
.VariablesGrid-container {
  display: grid;
  grid-template-columns: 93px 93px 93px;
  grid-auto-rows: 80px;
  grid-gap: 5px;
}
.Tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background: #f6f6f7;
  }
  &::before {
    border-radius: 1px;
    position: absolute;
    box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 31%) 0px 0px 1px;
    z-index: -1;
    border-width: 2px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: "";
  }
  &--text {
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}
