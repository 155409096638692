
.custom-input {
  margin-left: auto;
  align-items: center;
  padding: 0 !important;
  max-width: 20% !important;
  margin-right: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 600;
  max-width: 100%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  height: 36px;
  line-height: 2.28571em;
  vertical-align: middle;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #42526e;
  border-width: 2px;
  border-radius: 3px;
  text-decoration: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: var(--ds-background-neutral-subtle, none);
  padding: 0px 10px;
  outline: none;
}


#properties-confluence{
  width: 100% !important;
  &> div{
    min-width: 100% !important;
  }
}