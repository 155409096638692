.default-page-selector{
    background-color: #fafbfc;
    border-width: 2px;
    >div{
        border-color: #DFE1E6;
    }
}
.page-placeholder-checkbox{
    cursor: not-allowed;
    color: #adadadac;
    pointer-events: none;
}