.selectedPlaceholderSet {
  flex-grow: 1;
  flex-direction: column;
  min-width: 412px;
  height: 70vh;
  &-container {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    &.header {
      margin-top: 20px;
    }
    &.list {
      overflow: hidden auto;
      flex: 1 1 0%;
      max-height: 90%;
    }
  }
  &-element {
    min-width: 322px;
    max-width: 544px;
    padding: 0px 40px;
    width: 100%;
  }
  &-save-buttons{
    min-width: 322px;
    width: 544px;
    position: absolute;
    bottom: 0;
    height: 50px;
    padding-top: 15px;
  }
}
.permissionList-avatar {
  margin-right: 5px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
}
.permissionDialog-editButton {
  display: flex;
  font-size: 12px;
  padding: 0;
  float: right;
  justify-content: flex-start;
}
