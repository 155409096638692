.placeholderList-empty {
  height: 500px;
  width: 99%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  div {
    font-weight: 500;
    margin-top: 20px;
  }
}
.placeholderList-loading {
  height: 450px;
  width: 99%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
