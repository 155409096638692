.card-link {  
  text-decoration: none;
}
.grid-view {
  display: grid;
  margin: 10px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-columns: 4fr;
  grid-gap: 10px;
}
.property-card {
  height: 100%;
  display: flex;
  border-radius: 2px;
  width: 100%;
}
.page-title {
  font-weight: 400;
  font-size: 18px;
  margin-top: 3px;
  margin-left: 8px;
}
.property-rows {
  margin-top: 15px;
  margin-bottom: 8px;
}
.property-name {
  font-weight: 500;
}
.property-user-value {
  font-weight: 400;
  font-size: 15px;
  flex-flow: row wrap;
  position: relative;
  top: 0px;
}
.property-value {
  font-weight: 300;
  font-size: 14px;
  display: flex;
}
.property-card-grid-view {
  padding: 12px 16px 16px;
  border-radius: 5px;
  box-shadow: rgb(230 230 230) 1px -1px 1px 1px, rgb(230 230 230) -1px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}
.property-card-grid-view:hover {
  box-shadow: 1px 1px 5px rgb(185, 185, 185);
}
.page-icon {
  display: flex;
  flex-shrink: 0;
  line-height: 1;
}
.card-preview {
  padding-top: 23px;
}