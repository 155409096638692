.property-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  padding: 20px;
  overflow: scroll;
}
.property-form-wrapper-report {
  width: 100%;
  min-width: 700px;
  > div.property-row-wrapper-report {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-bottom: 23px !important;
    column-gap: 10px;
    > div {
      padding: 0 !important;
      margin: 0 !important;
      min-height: 50px !important;
    }
  }
  > div.short-version-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  > div.short-version-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  > div.short-version-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  > div.short-version-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  > * {
    padding: 0 !important;
  }
  > div:nth-child(5) {
    grid-row-start: 2;
  }
}

.property-row {
  width: 100%;
  > div:first-child {
    margin-top: 2px !important;
    margin-bottom: 2px;
  }
}

.property-row-report-view {
  float: left;
}
.property-row-wrapper-report {
  display: inline-flex;
  align-items: flex-start !important;
  width: 100%;

  height: fit-content;
  margin-bottom: -10px;
  & > div {
    margin-right: 3px;
  }
}

.property-row-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transform: translateY(10px);
}
