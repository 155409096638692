.input-group {
  display: inline-flex;
  width: 100%;
  align-items: flex-start !important;
  justify-content: center;
  min-width: 830px;
  & > div:first-child {
    margin-top: 3px;
  }
  & > div:last-child {
    margin-top: 3px;
  }
  & > div:not(:last-child) {
    margin-right: 3px;
  }
}

.input-group-text-field {
  margin-left: auto;
  align-items: center;
  padding: 0 !important;
  width: 270px !important;
  max-width: 270px !important;
  margin-right: 3px;
  & > div {
    color: #8993a3;
    outline: none;
    padding: 0 !important;
  }
  div {
    font-size: 14px;
    line-height: 1.4285714285714286;
  }
}

.row-icons {
  span {
    cursor: pointer;
  }
}

.isNameEmpty{
  .input-group-text-field{
    border-color: indianred !important;
  }
}


.add-save-entry {
  color: rgb(107, 119, 140);
  font-family: var(
    --ds-font-family-sans,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    "Oxygen",
    "Ubuntu",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif
  );
  font-style: normal;
  font-size: 14px;
  line-height: 1.42857142857143;
  font-weight: 400;
  pointer-events: all;
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  position: relative;
  background-color: var(--ds-surface, white);
  border-color: var(--ds-border-input, #f4f5f7);

  width: 96.5%;
  min-width: 790px;
  padding-left: 4px;
  padding-right: 15px !important;
  transform: translate(2px, 2px);
  &:hover {
    transform: translate(0px, 0px);
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0.5s;
    border-radius: var(--ds-radius-100, 3px);
    border-style: solid;
    border-width: var(--ds-width-100, 2px);
    box-shadow: none;
    outline: 0px !important;
  }
}
