.structure-option-container {
  padding: "0 0 50px 0";
  margin: "10px 7px 0 7px";
  border-radius: 3px;
  min-width: fit-content;

  .placeholder-unassigend {
    * {
      display: none !important;
    }
  }
  .placeholder-entity {
    * {
      display: inline-block !important;
      opacity: 0;
    }
  }
  &:hover {
    .create-card-entity {
      * {
        display: inline-block !important;
      }
    }
    .placeholder-entity {
      * {
        display: none !important;
      }
    }
    .create-card-unassigend {
      * {
        display: none !important;
      }
    }
    .placeholder-unassigend {
      * {
        display: inline-block !important;
      }
    }
  }
}
.select-container {
  :nth-child(2) {
    height: 40px;
    padding: 0 !important;
  }
  padding: 0 !important;
}

