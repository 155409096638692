.table-layout {
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-color: grey;
  border-collapse: separate;
  border-spacing: 0px;
  padding-top: 20px;
  width: 100%;
  min-width: 830px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857142857143;
  padding-right: 20px;
}
.table-preview {
  padding: 20px;
  padding-top: 32px;
}
.table-header {
  display: table-header-group;
  vertical-align: middle;
  border: none;
  border-collapse: separate;
  border-spacing: 0px;
}
.table-layout > * > tr > :first-child {
  border-left: none;
}
.table-layout * > tr > * {
  border: 1px solid rgb(235, 236, 240);
  border-right: none;
  height: 36px;
  padding: 0px;
}

.table-header > tr > th {
  border-top: none;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0px 6px;
  font-size: 15px;
  font-weight: 450;
  color: rgb(94, 108, 132);
  top: 0px;
}
.table-header > tr > th > div {
  display: flex;
  align-items: center;
}
.table-header > tr > th > div > span {
  min-height: 24px;
}
.table-header-name {
  position: relative;
  margin-left: 4px;
  bottom: 4px;
}
.table-header-desc::before {
  border-bottom: 3px solid #5e6c84;
}
.table-header-asc::after {
  border-top: 3px solid #5e6c84;
}

.table-body > tr > :first-child {
  border-top: none;
  border-left: none;
}
.table-header > tr > :first-child {
  min-width: 270px !important;
  width: 270px !important;
}
.page-link {
  cursor: pointer;
  text-decoration: none;
  display: inline;
  color: #0052cc;
  align-items: center;
  max-width: 100%;
  border-radius: 3px;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
  padding: 1px 3px;
  line-height: 1.714;
  white-space: pre-wrap;
  position: inherit;
  font-size: 14px;
  margin-left: 5px;
}
.page-link :hover {
  text-decoration: underline;
}
.page-link :visited {
  color: #0052cc;
}
.page-link > span {
  margin-left: 4px;
}
.table-body > tr {
  height: 70px;
}
.table-body > tr > td {
  border-top: none;
}
.table-body > tr > td {
  display: table-cell;
  text-align: left;
  height: 40px;
  padding: 5px;
  max-width: 200px;
}

.property-user-value {
  font-weight: 400;
  font-size: 15px;
  flex-flow: row wrap;
  position: relative;
  top: 0px;
}
.textProperty {
  border: 0px;
  box-sizing: border-box;
  cursor: inherit;
  font-size: 14px;
  width: 100%;
  line-height: 1.42857;
}
.textProperty :focus-within {
  border-color: #4c9aff;
}
